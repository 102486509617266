@import "@scss/common/_common-includes.scss";
.fresh-leads-details-wrapper {
    max-width: 872px;
    width: 100%;
    margin: 0 auto 72px;
}
.fresh-leads-details-wrapper__introduction {
    border: 1px solid $theme-border-color;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: $base-border-radius;
}
.fresh-leads-details-wrapper__link-referal {
    padding: 12px 16px;
    background-color: #f2f6ff;
    border-radius: $base-border-radius;
    margin-left: 44px;
    color: #276df1;
}
.fresh-leads-details-wrapper__input {
    max-width: 374px;
    width: 100%;
    border: 1px solid $theme-border-color;
}
.fresh-leads-details-wrapper__labelstyle {
    font-weight: $medium-font-weight !important;
    font-size: (15 / $base-font-size) + rem;
}
.fresh-leads-details-wrapper__about {
    border-top: 1px solid $theme-border-color;
    margin: 16px 0 0;
    padding: 16px 0 0 44px;
}
.fresh-leads-details-wrapper__link {
    color: #276df1;
    line-height: 1;
    font-size: (17 / $base-font-size) + rem;
    font-weight: $light-font-weight;
}
.fresh-leads-details-wrapper__select {
    display: inline-block;
    border: 1px solid $theme-border-color;
    padding: 16px;
    max-width: 225px;
    width: 100%;
    margin-bottom: 16px;
    color: #276ef1;
    border-radius: $base-border-radius;
}
.fresh-leads-details-wrapper__custom-text-area {
    border: 1px solid $theme-border-color;
    padding: 16px;
    border-radius: $base-border-radius;
    margin-bottom: 16px;
    &::placeholder {
        color: $theme-border-color;
        font-weight: $light-font-weight;
    }
}
.fresh-leads-details-wrapper__provider-comments-title {
    font-size: (15 / $base-font-size) + rem;
    font-weight: $medium-font-weight;
    margin-bottom: 2px;
    color: $theme-font-heading-color;
}
.fresh-leads-details-wrapper__review {
    border: 1px solid $theme-border-color;
    padding: 15px 14px;
    border-radius: $base-border-radius;
    margin-top: 16px;
}
.fresh-leads-details-wrapper__review-heading {
    font-size: (15 / $base-font-size) + rem;
    font-weight: $medium-font-weight;
    margin-bottom: 2px;
    color: $theme-font-primary-color;
}
.fresh-leads-details-wrapper__review-date-time {
    font-size: (11 / $base-font-size) + rem;
    font-weight: $light-font-weight;
    margin-bottom: 8px;
    color: $theme-terciary-color;
}
.fresh-leads-details-wrapper__review-description {
    font-size: (14 / $base-font-size) + rem;
    font-weight: $light-font-weight;
    color: $theme-terciary-color;
    line-height: 1.3;
}
.fresh-leads-details-wrapper__cta-button {
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 24px;
    &--theme-primary-btn {
        background-color: $theme-primary-color;
        color: $white-color;
        padding: 18px 70px;
        font-weight: $light-font-weight;
        @extend %transition;
        &:hover {
            color: $theme-primary-color;
            background-color: $white-color;
            border-color: $theme-primary-color;
        }
    }
    &--theme-secondary-btn {
        color: #276ef1;
        background-color: $white-color;
        padding: 10px 24px;
        border: 1px solid $theme-border-color;
        @extend %transition;
        &:hover {
            background-color: #276ef1;
            color: $white-color;
            border-color: #276ef1;
        }
    }
}

.profile_circle {
    background-color: #2d5743;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 10px;
    .title {
      color: white;
      font-size: 14px;
    }
  }