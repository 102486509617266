@import "@scss/common/_common-includes.scss";
.dashboard-home-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    // max-width: 1024px;
    width: 100%;
}
.dashboard-card-wrapper {
    margin-top: 16px;
    border: 1px solid $theme-border-color;
    padding: 16px 32px 16px 16px;
    border-radius: $base-border-radius;
    @extend %transition;
    &:hover {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.29);
        transform: translateY(-2px);
    }
}
.dashboard-card-wrapper__title {
    font-size: (17 / $base-font-size) + rem;
    font-weight: $light-font-weight;
    margin-bottom: 4px;
}
.dashboard-card-wrapper__count {
    font-size: (52 / $base-font-size) + rem;
    font-weight: $light-font-weight;
    line-height: 1.3;
}
